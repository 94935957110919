a {color: #b72f25;}
blockquote.color {background: #b72f25;}
.dropcap.color {background-color: #b72f25;}
.parallax-background.color .item-mask {background: #b72f25;}
.section.alternate.color {background: #b72f25;}
.menu li a:hover, .menu li.sfHover a, .menu li:hover ul li a:hover, .menu li li:hover ul li a:hover, .menu li ul li.sfHover a {color: #b72f25;}
#three-lines-menu a:hover {background: #b72f25;}
#mobile-menu ul li a.active {background: #b72f25;}
#mobile-menu ul li a:hover {color: #b72f25;}
#footer a:hover {color: #b72f25;}
.bra_tweets .tweet_text a, #footer .bra_tweets .tweet_text a {color: #b72f25;}
.bra_tweets a:hover , #footer .bra_tweets a:hover, .bra_tweets a.twitter_time:hover, #footer .bra_tweets a.twitter_time:hover {color: #b72f25;}
.photostream a .item-mask:hover {background-color: #b72f25;}
.team h2 {color: #b72f25;}	
.team .social-personal li a:hover {color:#b72f25;}
.skills-graph li span {background-color: #b72f25;}
.grey:hover { background-color:#b72f25; }
.highlight1 {background-color: #b72f25;}
.item-info-overlay h3.title, .item-info-overlay h3.title a {color: #b72f25;}
.item-info-overlay .outline:hover {box-shadow: 0 0 0 1px #b72f25 inset;	background-color: #b72f25;}
.filterable li.current a {color:#b72f25;}
ul.item-nav li:hover {background: #b72f25;}
.contact-success {background-color: #b72f25;}
.tagcloud a:hover {background-color: #b72f25;}


.widget a:hover {color: #b72f25;}
.timeline .post-info a:hover {color: #b72f25;}
.timeline .post-info li.comments a {color: #b72f25;}
.post-title h2.title, .post-title h2.title a {color: #b72f25;}
.comment .author a:hover, .comment .children .author a:hover {color: #b72f25;}
.comment .reply:hover {background-color:#b72f25;}
.comment .children .comment-meta .reply:hover {background-color: #b72f25;}
.archive.fullwidth li:hover .title a {color: #b72f25;}
.archive-nav .tagcloud a:hover {background-color: #b72f25;}
#archive-nav a.active {color: #b72f25;}


.flex-direction-nav a:hover {background-color:#b72f25;}


.fwslider nav span:hover {background-color: #b72f25;}
.cbp-fwdots span:hover {border: 2px solid #b72f25;background-color: #b72f25;}
.cbp-fwdots span.cbp-fwcurrent {border: 2px solid #b72f25;}


.bx-wrapper .bx-pager.bx-default-pager a:hover {border: 2px solid #b72f25;background-color: #b72f25;}
.bx-wrapper .bx-pager.bx-default-pager a.active {border: 2px solid #b72f25;}
.section.alternate.dark .bx-wrapper .bx-pager.bx-default-pager a:hover {border: 2px solid #b72f25;background-color: #b72f25;}
.bx-wrapper .bx-prev:hover, .bx-wrapper .bx-next:hover {background-color:#b72f25;}


div.jp-play-bar {background-color: #b72f25;}
div.jp-volume-bar-value {background-color: #b72f25;}