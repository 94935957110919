
/*---------------960 Screen size---------------*/
@media only screen and (min-width: 959px) and (max-width: 1170px) {
.header, #mobile-menu ul, .page-header, .page-header .holder, .section, .section .holder { width: 900px; }
#three-lines-menu {	display: none; }
.alternate, .parallax-background { width: 100%; }
.item-info { padding: 15px; }
.hexagon.col3 { margin: 0 0 0 115px; }
.hexagon.col3 li { margin: 0 0 30px 30px; }
.hexagon.col4 { margin: 0; }
.hexagon.col4 li { margin: 0 0 25px 25px; }
.circle.col3, .circle.col4 { margin: 0; }
.circle.col3 li, .circle.col4 li { width: 290px; height: 290px; margin: 0 0 10px 12px; }
.circle.col3 .item-container img, .circle.col4 .item-container img { height: 290px; }
.circle.col3 li .item-info-overlay p, .circle.col4 li .item-info-overlay p, .col4 li .item-info-overlay p { line-height: 16px; font-size:11px; margin-top: 8px; }
.col4 .item-info-overlay p { line-height: 20px; font-size:12px; }
.circle.col4 li .item-info-overlay p:before, .col4 li .item-info-overlay p:before { display:none; }	
.gallery.col4 figcaption { padding: 10px; }
}

/*---------------iPad landscape---------------*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
.parallax-background {background-attachment: scroll;}
.alternate, .parallax-background { width: 100%; }
}

/*---------------iPad portrait---------------*/
@media only screen and (min-width: 768px) and (max-width: 959px) {
.header, #mobile-menu ul, .page-header, .page-header .holder, .section, .section .holder { width: 650px; }	
.alternate, .parallax-background { width: 100%; }
#header-wrapper { position: relative; }	
#header-wrapper { padding:30px 0; }
#primary-menu {display:none;}
.three-fourth, .one-fourth { width: 48%; }
.one-fourth:nth-child(2n+2) { margin-right: 0; margin-bottom: 25px; }
.button-group { width: 80%; }
.portfolio-grid {  }
.col2 li, .col3 li, .col4 li { width: 48.88%; }
.shaped li .item-info-overlay p { line-height: 20px; font-size:13px; }
.item-info { padding: 15px; }
.hexagon.col3 { margin: 0; }
.hexagon.col3 li { margin: 0 0 30px 20px; }
.hexagon.col4 { margin: 0 0 0 14px; }
.hexagon.col4 li { margin: 0 0 30px 30px; }
.circle.col3, .circle.col4 { margin: 0; }
.circle.col3 li, .circle.col4 li { width: 300px; height: 300px; margin: 0 0 20px 20px; }
.circle.col3 .item-container img, .circle.col4 .item-container img { height: 300px; }
.circle.col3 li .item-info-overlay p, .circle.col4 li .item-info-overlay p { line-height: 16px; font-size:11px; }
.circle.col3 li .item-info-overlay p:before, .circle.col4 li .item-info-overlay p:before { margin-bottom: 8px; }
.grid.row3 div, .grid.row4 div, .grid.row5 div, .grid.row6 div { width: 33%; }
.grid.row4 div:nth-child(4n+4), .grid.row5 div:nth-child(5n+5), .grid.row6 div:nth-child(6n+6) { margin-right: 0.5%; }
.grid.row4 div:nth-child(3n+3), .grid.row5 div:nth-child(3n+3), .grid.row6 div:nth-child(3n+3) { margin-right: 0; }
.google-map, .google-map-2, .google-map-3 {	height: 400px; }
.form input, .form .contact-error, .contact-success { width: 100%; }
.contact-button { margin-top: 0; }
.parallax-background {background-attachment: scroll;}
}

/*---------------iPhone landscape---------------*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
.header, #mobile-menu ul, .page-header, .page-header .holder, .section, .section .holder { width: 420px; }
.alternate, .parallax-background { width: 100%; }
#primary-menu {display:none;}
.page-header div { width: 100%; }
.page-header .alignright { float: left; }
.page-header div:last-child { margin-bottom: 0; }
.one, .one-half, .two-third, .one-third, .one-fourth, .three-fourth, .one-fifth { float:left; position:relative; width: 100%; margin-right: 0; margin-bottom: 25px; }
.button-group { width: 100%; }
.button-group .button {	font-size: 15px; }
.portfolio-grid { width: 100%; margin: 0; }
.portfolio-grid ul li { margin: 0 0 15px 0; }
.col2 li, .col3 li, .col4 li { width: 100%; }
.item-info { padding: 15px; }
.shaped li .item-info-overlay p { line-height: 20px; font-size:13px; }
.hexagon.col3 { margin: 0 0 0 60px; }
.hexagon.col3 li { margin: 0 0 30px 0; }
.hexagon.col4 { margin: 0 0 0 73px; }
.hexagon.col4 li { margin: 0 0 30px 0; }
.circle.col3 li, .circle.col4 li { width: 320px; height: 320px; margin: 0 0 20px 50px; }
.circle.col3 .item-container img, .circle.col4 .item-container img { height: 320px; }
.circle.col3 li .item-info-overlay p, .circle.col4 li .item-info-overlay p { line-height: 16px; font-size:11px; margin-top: 8px; }
.circle .item-info-overlay div { margin: 22% 9% 0 9%; }
.grid.row3 div, .grid.row4 div, .grid.row5 div, .grid.row6 div { width: 49.5%; }
.grid.row3 div:nth-child(3n+3), .grid.row4 div:nth-child(4n+4), .grid.row5 div:nth-child(5n+5), .grid.row6 div:nth-child(6n+6) { margin-right: 0.5%; }
.grid.row3 div:nth-child(2n+2), .grid.row4 div:nth-child(2n+2), .grid.row5 div:nth-child(2n+2), .grid.row6 div:nth-child(2n+2) { margin-right: 0; }
.google-map, .google-map-2, .google-map-3 {	height: 300px; }
.form input, .form .contact-error, .contact-success { width: 100%; }
.contact-button { margin-top: 0; }
.parallax-background {background-attachment: scroll;}
.parallax-background h2 { font-size: 26px; line-height: 30px; }
.parallax-background h3 { font-size: 18px; line-height: 22px; }
}

/*--------------iPhone portrait---------------*/
@media only screen and (min-width: 320px) and (max-width: 479px) {
.header, #mobile-menu ul, .page-header, .page-header .holder, .section, .section .holder { width: 300px; }
.alternate, .parallax-background { width: 100%; }
#primary-menu {display:none;}
.page-header div { width: 100%; }
.page-header .alignright { float: left; }
.page-header div:last-child { margin-bottom: 0; }
.one, .one-half, .two-third, .one-third, .one-fourth, .three-fourth, .one-fifth { float:left; position:relative; width: 100%; margin-right: 0; margin-bottom: 25px; }
.button-group { width: 100%; }
.button-group .button {	font-size: 15px; }
.portfolio-grid { width: 100%; margin: 0; }
.portfolio-grid ul li { margin: 0 0 15px 0; }
.col2 li, .col3 li, .col4 li { width: 100%; }
.item-info { padding: 15px; }
.shaped li .item-info-overlay p { line-height: 20px; font-size:13px; }
.hexagon.col3, .hexagon.col3 li { margin: 0 0 20px 0; }
.hexagon.col4 li { margin: 0 0 20px 14px; }
.circle.col3 li, .circle.col4 li { width: 300px; height: 300px; margin: 0 0 20px 0; }
.circle.col3 .item-container img, .circle.col4 .item-container img { height: 300px; }
.circle .item-info-overlay div { margin: 20% 9% 0 9%; }
.grid.row3 div, .grid.row4 div, .grid.row5 div, .grid.row6 div { width: 100%; margin-bottom: 5px; }
.grid.row3 div:nth-child(3n+3), .grid.row4 div:nth-child(4n+4), .grid.row5 div:nth-child(5n+5), .grid.row6 div:nth-child(6n+6) { margin-right: 0; }
.google-map, .google-map-2, .google-map-3 {	height: 200px; }
.form input, .form .contact-error, .contact-success { width: 100%; }
.contact-button { margin-top: 0; }
.flexslider.fullwidth { height:360px; margin-bottom:-2px; }
.button-group .button {	width: 100%; }
.button-group .button:first-child {	border-top-left-radius: 3px; border-top-right-radius: 3px; border-bottom-left-radius: 0; border-botom-right-radius: 0; border-bottom: 1px solid #fff;	border-right: none; }
.button-group .button:last-child { border-bottom-left-radius: 3px; border-bottom-right-radius: 3px; border-top-left-radius: 0; border-top-right-radius: 0; }
.parallax-background {background-attachment: scroll;}
.parallax-background h2 { font-size: 26px; line-height: 30px; }
.parallax-background h3 { font-size: 18px; line-height: 22px; }
}


/*---------------Blog Responsive CSS---------------*/

/*---------------960 Screen size---------------*/
@media only screen and (min-width: 959px) and (max-width: 1170px) {
#inner-content { width: 650px; }
#sidebar { width: 200px; }
.blog1 .post { padding: 50px 0; }
.blog1 .post-media { width: 900px; }
.blog1 .post-content { width: 650px; }
.blog1.two-half .post-holder { width: 900px; }
.blog-holder { width: 103%; margin: 0 0 0 -3%; }
.blog-holder.blog5 .post, .blog-holder.blog6 .post { width: 46.89%; margin: 0 0 3% 3%; }
.blog-holder.blog7  { width: 100%; margin: 0; }
.blog-holder.blog7 .post { width: 100%; margin: 0 0 5% 0; }
.blog-single .post-media { width: 900px; }
.blog-single .post-content { width: 650px; }
#comment-form.form input { width: 75%; }
#comment-form, #comment-form.form textarea { width: 100%; } 
.comment .comment-wrap, .comment .comment-meta { width: 85.4%; }
.comment .children .comment-wrap, .comment .children .comment-meta { width: 83%; }
.comment .children .children .comment-wrap, .comment .children .children .comment-meta { width: 79.8%; }
.comment .children .children .children .comment-wrap, .comment .children .children .children .comment-meta { width: 75%; }
.comment .children .children .children .children .comment-wrap, .comment .children .children .children .children .comment-meta { width: 100%; }
.comment .children .children .children  .children .children .comment-wrap, .comment .children .children .children .children .children .comment-meta { width: 100%; }
.archive-nav div, .archive.fullwidth div { width: 900px; }
}

/*---------------iPad portrait---------------*/
@media only screen and (min-width: 768px) and (max-width: 959px) {
#inner-content, #sidebar { width: 650px; }
#inner-content { width: 650px; border-bottom: 2px solid #eee; margin: 0 0 40px 0; padding: 0 0 40px 0; }
.blog1 .post { padding: 50px 0; }
.blog1 .post-media { width: 650px; }
.blog1 .post-content { width: 500px; }
.blog1.two-half .post-holder { width: 650px; }
.blog3 .post-media { margin-bottom: 20px; }
.blog-holder { width: 103%; margin: 0 0 0 -3%; }
.blog-holder.blog5 .post, .blog-holder.blog6 .post { width: 46.89%; margin: 0 0 3% 3%; }
.blog-holder.blog7  { width: 100%; margin: 0; }
.blog-holder.blog7 .post { width: 100%; margin: 0 0 5% 0; }
.timeline .post-content { width: 100%; }
.blog-single .post-media { width: 650px; }
.blog-single .post-content { width: 500px; }
#comment-form.form input { width: 75%; }
#comment-form, #comment-form.form textarea { width: 100%; } 
.comment .comment-wrap, .comment .comment-meta { width: 85.4%; }
.comment .children .comment-wrap, .comment .children .comment-meta { width: 83%; }
.comment .children .children .comment-wrap, .comment .children .children .comment-meta { width: 79.8%; }
.comment .children .children .children .comment-wrap, .comment .children .children .children .comment-meta { width: 75%; }
.comment .children .children .children .children .comment-wrap, .comment .children .children .children .children .comment-meta { width: 100%; }
.comment .children .children .children  .children .children .comment-wrap, .comment .children .children .children .children .children .comment-meta { width: 100%; }
.archive-nav div, .archive.fullwidth div { width: 650px; }
}

/*---------------iPhone landscape---------------*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
#inner-content, #sidebar { width: 420px; }
#inner-content { border-bottom: 2px solid #eee; margin: 0 0 40px 0; padding: 0 0 40px 0; }	
.blog1 .post { padding: 50px 0; }
.blog1 .post-media, .blog1 .post-content { width: 420px; }
.blog1.two-half .post-holder, .blog1.two-half .post-media, .blog1.two-half .post-media.right, .blog1.two-half .post-content, .blog1.two-half .post:nth-child(2n+2) .post-media { width: 420px; }
.blog1.two-half .post-media { margin-bottom: 20px; }
.blog3 .post-media, .blog3 .post-content { width: 100%; margin: 0; }
.blog3 .post-media { margin-bottom: 20px; }
.blog-holder { width: 100%; margin: 0; }
.blog-holder.blog5 .post, .blog-holder.blog6 .post, .blog-holder.blog7 .post { width: 100%; margin: 0 0 30px 0; }
.timeline .post-content { width: 100%; }
.blog-single .post-media, .blog-single .post-content { width: 420px; }
#comment-form.form input { width: 75%; }
#comment-form, #comment-form.form textarea { width: 100%; } 
.comment .comment-wrap, .comment .comment-meta { width: 85.4%; }
.comment .children .comment-wrap, .comment .children .comment-meta { width: 83%; }
.comment .children .children .comment-wrap, .comment .children .children .comment-meta { width: 79.8%; }
.comment .children .children .children .comment-wrap, .comment .children .children .children .comment-meta { width: 75%; }
.comment .children .children .children .children .comment-wrap, .comment .children .children .children .children .comment-meta { width: 100%; }
.comment .children .children .children  .children .children .comment-wrap, .comment .children .children .children .children .children .comment-meta { width: 100%; }
.archive-nav div, .archive.fullwidth div { width: 420px; }
.archive-nav ul { width: 50%; }
}

/*---------------iPhone portrait---------------*/
@media only screen and (min-width: 320px) and (max-width: 479px) {
#inner-content, #sidebar { width: 300px; }
#inner-content { border-bottom: 2px solid #eee; margin: 0 0 40px 0; padding: 0 0 40px 0; }
.blog1 .post { padding: 50px 0; }
.blog1 .post-media, .blog1 .post-content { width: 300px; }
.blog1.two-half .post-holder, .blog1.two-half .post-media, .blog1.two-half .post-media.right, .blog1.two-half .post-content { width: 300px; }
.blog1.two-half .post-media { margin-bottom: 20px; }
.blog3 .post-media, .blog3 .post-content { width: 100%; margin: 0; }
.blog3 .post-media { margin-bottom: 20px; }
.blog-holder { width: 100%; margin: 0; }
.blog-holder.blog5 .post, .blog-holder.blog6 .post, .blog-holder.blog7 .post { width: 100%; margin: 0 0 30px 0; }
.timeline .post-content { width: 100%; }
.blog-single .post-media, .blog-single .post-content { width: 300px; }
#comment-form.form input { width: 75%; }
#comment-form, #comment-form.form textarea { width: 100%; } 
.comment .comment-wrap, .comment .comment-meta { width: 100%; }
.comment .children .comment-wrap, .comment .children .comment-meta { width: 100%; }
.comment .children .children .comment-wrap, .comment .children .children .comment-meta { width: 100%; }
.comment .children .children .children .comment-wrap, .comment .children .children .children .comment-meta { width: 100%; }
.comment .children .children .children .children .comment-wrap, .comment .children .children .children .children .comment-meta { width: 100%; }
.archive-nav div, .archive.fullwidth div { width: 300px; }
.archive-nav ul { width: 100%; }
.archive-nav ul li { margin: 0 0 5px 0; }
.post-nav li { width: 100%;	padding: 0 0 15px 0; margin: 0 0 15px 0; }
.post-nav li.prev {	text-align: left; }
.post-nav li:first-child { border-bottom: 2px solid #eee; border-right: none; }
.post-nav li:last-child { padding: 0; margin: 0; }
}