/* overrides for style.css */

#primary-menu {
	margin-top: 52px;
}

.menu li:after {
	content: "|";
	color: #999;
}
.menu li:last-child:after {
    content: "";
}


.item-info h4, .item-info-overlay h4 {
	font-size:12px;
}

.filterable li a {
	font-size: 16px;
}

.gallery .caption h4 {
	font-size:12px;
}

.portfolio-grid_img:hover {
	cursor: pointer;
}